body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --mini-txt: Manrope;
  --h3: Mulish;
  --font-montserrat: Montserrat;

  /* font sizes */
  --mini-txt-size: 0.88rem;
  --h3-size: 1.25rem;
  --ttle-size: 2.25rem;
  --btn-size: 2rem;
  --h2-size: 3rem;
  --h1-size: 3.5rem;

  /* Colors */
  --bg: #fbfafa;
  --black: #0f0f0f;
  --dark-blue1: #00071e;
  --dark-blue: #0061f2;
  --white: #f2f2f2;
  --blue: #063fc0;
  --color-white: #fff;
  --dark-grey: #49494a;
  --color-steelblue: #1e95bb;
  --color-darkorange: #f58b0e;
  --red: #ed2040;

  /* Gaps */
  --gap-91xl: 6.88rem;
  --gap-31xl: 3.13rem;
  --gap-3xs: 0.63rem;
  --gap-mini: 0.94rem;
  --gap-xl: 1.25rem;
  --gap-xs: 0.75rem;
  --gap-11xl: 1.88rem;
  --gap-5xl: 1.5rem;

  /* Paddings */
  --padding-3xs: 0.63rem;
  --padding-13xl: 2rem;
  --padding-lg: 1.13rem;
  --padding-21xl: 2.5rem;
  --padding-5xs-1: 0.44rem;
  --padding-xs: 0.75rem;
  --padding-3xs-3: 0.58rem;
  --padding-xl: 1.25rem;
  --padding-11xl: 1.88rem;

  /* Border radiuses */
  --br-9xs: 4px;
}
